import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "next-i18next";

import { Button } from "components/atoms/button/button.component";
import { Footer } from "components/atoms/footer/footer.component";
import { ModalFooterProps } from "./modal-footer.interface";
import { breakpoints } from "lib/breakpoints";
import { StyledButtonBlock } from "./modal-footer.styled";

export const ModalFooter = ({
    firstButtonText,
    onFirstButtonClick,
    isFirstButtonDisabled = false,
    firstButtonTestId,
    secondButtonText,
    onSecondButtonClick,
    isSecondButtonDisabled = false,
    secondButtonTestId,
    thirdButtonText,
    onThirdButtonClick,
    isThirdButtonDisabled = false,
    thirdButtonTestId,
    thirdButtonWidth,
    showSecondButtonOnMobileOnly,
    showThirdButtonOnMobileOnly,
    isLoading = false,
    firstButtonWidth,
    secondButtonWidth,
}: ModalFooterProps) => {
    const { t } = useTranslation("common");

    const isMobile = useMediaQuery(`(max-width: ${`${breakpoints.sm}px`})`);

    const showSecondButton = showSecondButtonOnMobileOnly
        ? isMobile && !!onSecondButtonClick
        : !!onSecondButtonClick;

    const showThirdButton = showThirdButtonOnMobileOnly
        ? isMobile && !!onThirdButtonClick
        : !!onThirdButtonClick;

    return (
        <Footer>
            <StyledButtonBlock>
                <Button
                    disabled={isFirstButtonDisabled}
                    loading={isLoading}
                    onClick={onFirstButtonClick}
                    data-testid={firstButtonTestId}
                    width={firstButtonWidth}
                    showTitle
                >
                    {firstButtonText || t("modal.button.save", "Save")}
                </Button>
            </StyledButtonBlock>

            {showSecondButton && (
                <StyledButtonBlock>
                    <Button
                        variant="outlined"
                        disabled={isSecondButtonDisabled}
                        onClick={onSecondButtonClick}
                        data-testid={secondButtonTestId}
                        width={secondButtonWidth}
                        showTitle
                    >
                        {secondButtonText || t("modal.button.cancel", "Cancel")}
                    </Button>
                </StyledButtonBlock>
            )}

            {showThirdButton && (
                <StyledButtonBlock>
                    <Button
                        variant="outlined"
                        disabled={isThirdButtonDisabled}
                        onClick={onThirdButtonClick}
                        data-testid={thirdButtonTestId}
                        width={thirdButtonWidth}
                        showTitle
                    >
                        {thirdButtonText || t("modal.button.cancel", "Cancel")}
                    </Button>
                </StyledButtonBlock>
            )}
        </Footer>
    );
};
