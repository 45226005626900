import { ZodEffects, ZodString, z } from "zod";
import { TFunction } from "next-i18next";
import { omit } from "lodash";

import {
    FIELD_REQUIRED_MESSAGE,
    FIELD_REQUIRED_MESSAGE_KEY,
} from "../constants";
import { stringValidatorsByType } from "./validators";
import { CustomParams } from "./custom/types";
import { customValidator } from "./custom";
import {
    ValidationRule,
    ValidatorParams,
    StringValidatorFunction,
    ValidatorsMap,
} from "./types";

type createStringValidationPayload = {
    validationRules: ValidationRule[];
    customValidationRules?: CustomParams[];
    t: TFunction;
};

const callValidator = <T extends keyof ValidatorsMap>(
    ruleType: T,
    params: ValidatorParams<ValidatorsMap[T]>,
) => {
    const validator = stringValidatorsByType[
        ruleType
    ] as StringValidatorFunction<typeof params>;
    return validator(params);
};

export const createStringValidation = ({
    validationRules,
    customValidationRules,
    t,
}: createStringValidationPayload) => {
    let validation = z.string({
        required_error: t(FIELD_REQUIRED_MESSAGE_KEY, FIELD_REQUIRED_MESSAGE),
    });

    validationRules.forEach((validationRule) => {
        validation = callValidator(validationRule.type, {
            t,
            validation,
            validationRule: omit(validationRule, "type") as any,
        });
    });

    let resultValidation: ZodString | ZodEffects<ZodString> = validation;

    if (customValidationRules) {
        customValidationRules.forEach((validationRule) => {
            resultValidation = customValidator({
                t,
                validation,
                validationRule,
            });
        });
    }

    return resultValidation;
};
