import {
    FORBID_START_END_2_SPACES_REGEX,
    SPACES_LIMIT_TRANSLATE_KEY,
} from "./constants";
import { SpacesValidatorParams } from "./types";

export const spacesValidator: SpacesValidatorParams = ({ validation, t }) => {
    return validation.regex(
        FORBID_START_END_2_SPACES_REGEX,
        t(
            SPACES_LIMIT_TRANSLATE_KEY,
            "space before and after text and double spaces are not supported",
        ),
    );
};
