import { z } from "zod";
import { TFunction } from "next-i18next";

import {
    FIELD_REQUIRED_MESSAGE_KEY,
    FIELD_REQUIRED_MESSAGE,
} from "../constants";

type createRequiredMultiselectValidatorPayload = {
    t: TFunction;
    isObjectOption?: boolean;
};

export const createRequiredMultiselectValidator = ({
    t,
    isObjectOption = false,
}: createRequiredMultiselectValidatorPayload) => {
    const validation = z
        .array(!isObjectOption ? z.string() : z.object({}))
        .nonempty(t(FIELD_REQUIRED_MESSAGE_KEY, FIELD_REQUIRED_MESSAGE));

    return validation;
};
