import { AvailableSymbols } from "./types";

const charactersToEscape = [
    "[",
    "]",
    "s",
    "^",
    "*",
    "+",
    "(",
    ")",
    "?",
    ".",
    "$",
    "|",
    "-",
];

export const createAvailableSymbolsRegex = (symbols: AvailableSymbols[]) => {
    const mappedSymbols = symbols.map((symbol) =>
        charactersToEscape.includes(symbol) ? `\\${symbol}` : symbol,
    );

    return new RegExp("^[\\p{L}\\p{N}" + mappedSymbols.join("") + "]*$", "u");
};
