import styled from "styled-components";

import { colors, shadow } from "lib/palette";
import { zIndexes } from "lib/z-indexes";

export const StyledFooter = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background: ${colors.white};
    z-index: ${zIndexes.footer};

    &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        top: -10px;
        height: 10px;
        background: ${shadow.bottom};
        border-bottom: 1px solid ${colors.blue_3};
    }
`;
