import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";

const generateHeight = (withMobileHeader: boolean, withPagination: boolean) => {
    if (withMobileHeader && withPagination) {
        return "calc(100vh - 178px)";
    }

    if (withMobileHeader) {
        return "calc(100vh - 113px)";
    }

    return "calc(100vh - 56px)";
};

export const StyledContent = styled.div<{
    withMobileHeader: boolean;
    withPagination: boolean;
    overflowY: "auto" | "hidden";
}>`
    width: 100%;
    height: ${({ withPagination }) =>
        withPagination ? "calc(100vh - 125px)" : "calc(100vh - 60px)"};
    overflow-y: ${({ overflowY }) => overflowY};
    position: relative;

    @media (max-width: ${`${breakpoints.xl}px`}) {
        height: ${({ withMobileHeader, withPagination }) =>
            generateHeight(withMobileHeader, withPagination)};
    }

    @media (max-width: ${`${breakpoints.sm}px`}) {
        padding-bottom: 20px;
    }
`;
