import { Modal } from "@mantine/core";
import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import {
    StyledWarningModalButtonsBlockProps,
    StyledWarningModalProps,
} from "./warning-modal.interface";
import { colors } from "lib/palette";
import { zIndexes } from "lib/z-indexes";

export const StyledModal = styled(Modal)<StyledWarningModalProps>`
    z-index: ${zIndexes.warningModal};
    position: relative;

    .mantine-Modal-overlay {
        background-color: rgb(0 0 0 / 15%);
    }

    .mantine-Modal-content {
        width: 100%;
        max-width: 400px;
        max-height: 100%;
        background: #fff;
        box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
        border-radius: 8px;
        padding: 40px;
        overflow-y: auto;
        flex: unset;

        @media (max-width: ${`${breakpoints.sm}px`}) {
            max-width: 100%;
            max-height: 100%;
            padding: 14px;
            margin-top: auto;
            border-radius: 8px 8px 0 0;
        }
    }

    .mantine-Modal-body {
        padding: 0;
    }

    @media (max-width: ${`${breakpoints.sm}px`}) {
        .mantine-Modal-inner {
            padding: 56px 0 0;
        }
    }
`;

export const StyledModalCloseButton = styled.button`
    width: max-content;
    height: max-content;
    display: flex;
    padding: 0;
    background: transparent;
    border: none;
    position: absolute;
    top: 14px;
    right: 14px;
    transition: 0.3s;
    transform: rotate(0deg);
    color: ${colors.blue_2};

    @media (max-width: ${`${breakpoints.sm}px`}) {
        position: sticky;
        right: 0;
        top: 0;
        margin-left: auto;
    }

    &:hover {
        transform: rotate(-90deg);
        transition: 0.3s;
        color: ${colors.black_1};
    }
`;

export const AttentionBlock = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 48px 1fr;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
`;

export const StyledWarningModalHeading = styled.h1`
    color: ${colors.black_1};
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;

    @media (max-width: ${`${breakpoints.sm}px`}) {
        padding: 0 6px;
    }
`;

export const StyledWarningModalMessage = styled.p`
    color: ${colors.black_6};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;

    @media (max-width: ${`${breakpoints.sm}px`}) {
        padding: 0 6px;
    }
`;

export const StyledWarningModalButtonsBlock = styled.div<StyledWarningModalButtonsBlockProps>`
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: ${({ buttonsDirection }) => buttonsDirection};
`;

export const StyledButtonBlock = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
`;
