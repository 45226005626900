import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import {
    ModalDesktopHeaderProps,
    testIds,
} from "./modal-desktop-header.interface";
import {
    StyledCloseButton,
    StyledModalDesktopHeader,
    StyledTitle,
} from "./modal-desktop-header.styled";

export const ModalDesktopHeader = ({
    title,
    onClose,
}: ModalDesktopHeaderProps) => (
    <StyledModalDesktopHeader>
        {title && <StyledTitle title={title}>{title}</StyledTitle>}
        <StyledCloseButton onClick={onClose} data-testid={testIds.closeButton}>
            <Icon name={Icons.closeNotificationModalSvg} />
        </StyledCloseButton>
    </StyledModalDesktopHeader>
);
