import { PolymorphicComponentProps } from "@mantine/utils";
import { ModalProps as MantineModalProps } from "@mantine/core";
import { ReactNode } from "react";
import { BUTTONS_DIRECTION } from "./warning-modal.constants";

export type WarningModalProps = {
    title?: string;
    message?: string;
    content?: ReactNode;
    opened: boolean;
    okButtonText?: string;
    cancelButtonText?: string;
    buttonsDirection?:
        | typeof BUTTONS_DIRECTION.row
        | typeof BUTTONS_DIRECTION.column
        | typeof BUTTONS_DIRECTION.rowReverse
        | typeof BUTTONS_DIRECTION.columnReverse;
    okButtonIsLoading?: boolean;
    cancelButtonIsLoading?: boolean;
    onOk?: () => void;
    onClose: () => void;
    onCancel?: () => void;
    okButtonVariant?: "primary" | "outlined" | "outlined-grey";
    cancelButtonVariant?: "primary" | "outlined" | "outlined-grey";
    okButtonTestId?: string;
    cancelButtonTestId?: string;
    showWarningIcon?: boolean;
    testId?: string;
};

export type StyledWarningModalProps = PolymorphicComponentProps<
    "div",
    MantineModalProps
>;

export type StyledWarningModalButtonsBlockProps = {
    buttonsDirection?:
        | typeof BUTTONS_DIRECTION.row
        | typeof BUTTONS_DIRECTION.column
        | typeof BUTTONS_DIRECTION.rowReverse
        | typeof BUTTONS_DIRECTION.columnReverse;
};

export const testIds = {
    warningModal: "warning-modal",
    closeFormButton: "close-form-button",
};
