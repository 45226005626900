import { ModalMobileHeaderProps } from "./modal-mobile-header.interface";
import { StyledMobileHeader } from "./modal-mobile-header.styled";

export const ModalMobileHeader = ({
    title,
    centered,
    className,
}: ModalMobileHeaderProps) => (
    <StyledMobileHeader className={className} centered={centered}>
        {title}
    </StyledMobileHeader>
);
