import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { ModalBodyProps } from "./modal-body.interface";

export const StyledBody = styled.div<ModalBodyProps>`
    width: ${({ width }) => width}px;
    height: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: ${breakpoints.sm}px) {
        width: 100%;
        padding-top: 56px;
        padding-bottom: 60px;
    }
`;

export const ScrollableContent = styled.div<{ withFooter?: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    max-height: ${({ withFooter }) =>
        withFooter ? "calc(100% - 110px)" : "calc(100% - 50px)"};

    @media (max-width: ${`${breakpoints.sm}px`}) {
        max-height: 100%;
    }
`;
