import { CustomValidatorParams } from "./types";

export const customValidator: CustomValidatorParams = ({
    validation,
    validationRule,
}) => {
    return validation.refine(
        validationRule.validator,
        validationRule.errorMessage,
    );
};
