import { PropsWithChildren } from "react";

export type ContentProps = PropsWithChildren<{
    withMobileHeader?: boolean;
    withPagination?: boolean;
    className?: string;
    overflowY?: "auto" | "hidden";
}>;

export const testIds = { pageContent: "page-content" };
