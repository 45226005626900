import { PropsWithChildren } from "react";

import { ModalBodyProps } from "./modal-body.interface";
import { ModalBody } from "./modal-body.component";
import { ScrollableContent } from "./modal-body.styled";

export const ScrollableModalBody = ({
    children,
    width,
    className,
    withFooter = true,
    ...props
}: PropsWithChildren<ModalBodyProps>) => (
    <ModalBody width={width} {...props}>
        <ScrollableContent className={className} withFooter={withFooter}>
            {children}
        </ScrollableContent>
    </ModalBody>
);
