import { toast, ToastContent, ToastOptions } from "./toast";

export const DEFAULT_SUCCESS_MESSAGE_KEY = "successToast.description.success";
export const DEFAULT_SUCCESS_MESSAGE = "Success.";

export const successToast = (
    message?: ToastContent,
    options?: ToastOptions,
) => {
    return toast(message || DEFAULT_SUCCESS_MESSAGE, {
        ...options,
        type: "success",
    });
};
