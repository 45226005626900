import { TFunction } from "next-i18next";

export const generateActivateDeactivateQuestion = (
    t: TFunction,
    isActive: boolean,
    title?: string,
) =>
    isActive
        ? t("common:activateDeactivateWarning.description.deactivateQuestion", {
              title,
              defaultValue: `Are you sure you want to deactivate ${title}?`,
          })
        : t("common:activateDeactivateWarning.description.activateQuestion", {
              title,
              defaultValue: `Are you sure you want to activate ${title}?`,
          });

export const ACTIVATE_BUTTON_KEY =
    "common:activateDeactivateWarning.button.activate";
export const DEACTIVATE_BUTTON_KEY =
    "common:activateDeactivateWarning.button.deactivate";
