import { Modal } from "@mantine/core";
import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { shadow } from "lib/palette";
import { zIndexes } from "lib/z-indexes";

export const StyledModal = styled(Modal)<{
    $showMobileHeaderShadow: boolean;
    height?: number;
    $isHidden: boolean;
}>`
    z-index: ${zIndexes.modal};
    ${({ $isHidden }) => $isHidden && "display: none;"}

    .mantine-Modal-overlay {
        z-index: ${zIndexes.modal - 1};
    }

    .mantine-Modal-inner {
        z-index: ${zIndexes.modal};
    }

    .mantine-Modal-content {
        width: max-content;
        max-width: 100%;
        max-height: 100%;
        box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
        border-radius: 8px;
        overflow: hidden;
        padding: 0;
        height: ${({ height }) => (height ? `${height}px` : "100%")};
        flex: unset;

        ${({ $showMobileHeaderShadow }) =>
            $showMobileHeaderShadow &&
            `
            @media(max-width: ${breakpoints.sm}px){
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 100%;
                    top: 56px;
                    height: 10px;
                    background: ${shadow.top};
                    z-index: ${zIndexes.shadow};
                }
            }
        `}
    }

    .mantine-Modal-body {
        height: ${({ height }) => (height ? `${height}px` : "100%")};
        padding: 0;
    }

    @media (max-width: ${`${breakpoints.sm}px`}) {
        z-index: ${zIndexes.mobileModal} !important;

        .mantine-Modal-content {
            width: 100%;
            height: 100%;
            box-shadow: none;
            border-radius: 0;
        }

        .mantine-Modal-body {
            height: 100%;
        }

        .mantine-Modal-inner {
            padding: 0;
            z-index: ${zIndexes.mobileModal};
        }

        .mantine-Overlay-root {
            display: none;
        }
    }

    @media (max-height: ${`${breakpoints.sm}px`}) {
        .mantine-Modal-content {
            height: 100%;
        }

        .mantine-Modal-body {
            height: 100%;
        }
    }
`;
